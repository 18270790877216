import React from "react";
import { Col, Row, Button } from "reactstrap";
import { BsPersonPlusFill } from "react-icons/bs";
import Table from "../../../components/Table/Table";
import AttendanceFilter from "./AttendanceFilter";
import { notificationAction } from "../../../redux/reducers/notification_reducer";
import * as ActionTypes from "../../../redux/actions/types";
import moment from "moment";
import LoadingMask from "../../../components/Loader/LoadingMask";
import ImageModel from '../../../components/image/ImageModel'

import { connect } from "react-redux";
import { getAttendanceReportList } from "./apiCalls";
import { Link } from "react-router-dom";
import { MdOutlinePostAdd } from "react-icons/md";
let dateOne = moment().startOf("day").toDate();
let startDate = moment(dateOne).format("YYYY-MM-DD HH:mm");
let dateTwo = moment()
  .startOf("day")
  .add(23, "hour")
  .add(59, "minute")
  .toDate();
let endDate = moment(dateTwo).format("YYYY-MM-DD HH:mm");
let initialRange = startDate + " / " + endDate;
class Attendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictureModal:false,
      imageUrl:"",
      person_type: "Z0FBQUFBQmlNd2dXTVg4QklvWTVlOHBMdWE3QUc4Y1FfbnhxU2RVNGpVRDVabUNucVV3S0s4TTZJSi02RjhHRzVROWVoV052eUhBbm8xbU5KbmF4YUkwVXNxeGNIRmJwN0E9PQ==",
      paginationData: [],
      toggle: false,
      selectedDateRange: initialRange,
      attendanceList: [],
      download: true,
      selectedAttendanceFilters: {
        person_number: "",
        student_name: "",
        class_type: "",
        section:null,
        departments:null,
        attendance_type:null
      },
      selectedValues: {
        selected_section: { label: "All", value: null },
        selected_class_type: { label: "All", value: null },
        selected_attendance_type: { label: "All", value: null }
      },
    };
  }

  componentDidMount=async ()=> {
    const {selectedValues, selectedAttendanceFilters} = this.state
    await this.props.RecordPerPage(10)
    await this.props.PageNo(1);
    if(this.props.location.state != null){
    const {present } = this.props.location.state;
    this.setState(
      {
        selectedValues: {
          ...selectedValues,
          selected_attendance_type: present ? present : { label: "All", value: -1 },
        },
        selectedAttendanceFilters: {
          ...selectedAttendanceFilters,
          attendance_type: present ?.value,
        },
      },
      () => this.handleFilterDataTableList()
    )}
    else{
      this.handleFilterDataTableList();
    }
     
   }

  handelStudentNameChange = (selectedOption) => {
    if (!selectedOption) return;
    this.setState({
      selectedStatus: selectedOption,
    });
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.sortname !== this.props.sortname ||
      prevProps.sortby !== this.props.sortby
    ) {
      // this.props.Loading(true)
      this.handleFilterDataTableList();
    }
    if ( prevProps.pageno !== this.props.pageno) {
       this.handleFilterDataTableList();
     
      
    }
    if (prevProps.recordperpage !== this.props.recordperpage) {
      // this.props.Loading(true);
      this.props.PageNo(1);
      this.handleFilterDataTableList();
    }
  }

  handleAttendanceClear = (e) => {
    let startDate = moment().startOf("date").format("YYYY-MM-DD HH:mm");
    let endDate = moment()
      .startOf("date")
      .hours(23)
      .minutes(59, "hour")
      .seconds(59, "min");
    let Range = startDate + " / " + endDate.format("YYYY-MM-DD HH:mm");
    this.props.PageNo(1);
    this.props.RecordPerPage(10);
    this.setState(
      {
        selectedAttendanceFilters: {
          person_number: "",
          student_name: "",
          attendance_type:null

        },
        selectedDateRange: Range,
        selectedValues: {
          selected_section: { label: "All", value: null },
          selected_class_type: { label: "All", value: null },
          selected_attendance_type:{ label: "All", value: null }
         
        },
      },
      () => this.handleFilterDataTableList()
    );
  };

  handleApplyFilter =()=>{
    // this.props.Loading(true);
    this.props.PageNo(1);
    this.handleFilterDataTableList()
  }
  handleFilterFieldChange = (e) => {
    const { name, value } = e.target;
    const { selectedAttendanceFilters } = this.state;

    if (name === "student_name" || name === "person_number") {
      this.validateFilterField({ name, value });
    }

    this.setState({
      selectedAttendanceFilters: {
        ...selectedAttendanceFilters,
        [name]: value,
      },
    });
    return;
  };
  validateFilterField = ({ name, value }) => {
    const { selectedAttendanceFilters } = this.state;
    this.setState({
      selectedAttendanceFilters: {
        ...selectedAttendanceFilters,
        [name]: value,
      },
    });
  };
  handelDropDownChange = (selectedOption, obj) => {
    const { selectedValues, selectedAttendanceFilters } = this.state;
    const { name } = obj;
    if (!selectedOption) {
      let defaultOption = { value: "", label: "All" };
      if (
        obj.name === "section"  ||obj.name === "class_type" || obj.name === "branch"  || obj.name === "attendance_type"
      ) {
        this.setState({
          selectedValues: {
            ...selectedValues,
            [`selected_${obj.name}`]: defaultOption,
          },
          selectedAttendanceFilters: {
            ...selectedAttendanceFilters,
            [name]: null,
          },
        });
      }
      return;
    }
    
      this.setState({
        selectedValues: {
          ...selectedValues,
          [`selected_${name}`]: selectedOption,
        },
        selectedAttendanceFilters: {
          ...selectedAttendanceFilters,
          [name]: selectedOption.id,
        },
      });
  
  };
  handleExportCsv = () => {
    this.setState({ appLoadingState: true });
    const {person_type,  selectedDateRange}=this.state

    const {
      person_number,
      student_name,
      class_type,
      section,
    attendance_type
    } = this.state.selectedAttendanceFilters;
    getAttendanceReportList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      student_name ? student_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      class_type ? class_type : null,
      section ?section :null,
      attendance_type ? attendance_type : null,
      selectedDateRange ? selectedDateRange : null,
      person_type?person_type:null,
      this.state.download ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          if (response.data.file_url != null) {
            window.open(response.data.file_url, "_blank");
          }
        }
          this.setState({ appLoadingState: false });
      })
      .catch((error) => console.log("Erorr in getting students ", error));
  };
  handleFilterDataTableList = async () => {
    const {person_type,selectedValues,selectedDateRange}=this.state
    const {}=selectedValues;
    this.setState({ appLoadingState: true});

    const {
      person_number,
      student_name,
      class_type,
      section,
attendance_type
    } = this.state.selectedAttendanceFilters;
    await getAttendanceReportList(
      this.props.recordperpage ? this.props.recordperpage : null,
      this.props.pageno ? this.props.pageno : null,
      this.props.sortname ? this.props.sortname : null,
      this.props.sortby ? this.props.sortby : null,
      student_name ? student_name.replace(/^\s+|\s+$/g, "") : null,
      person_number ? person_number.replace(/^\s+|\s+$/g, "") : null,
      class_type ? class_type : null,
      section ?section :null,
      attendance_type ? attendance_type : null,
      selectedDateRange ? selectedDateRange : null,
      person_type?person_type:null,
      this.state.acceptance ? 1 : null
    )
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            attendanceList: response.data.dataset,
            paginationData: response.data.pagination,
            appLoadingState:false
          });
         
        }
        else if (response.code != 200) {
          this.setState({ appLoadingState: false})
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
        }
        else {
         
        }
      })
      .catch((error, response) => {
        const info = {
          message: error.message,
          status: "error",
        };
       
        this.props.notificationAction(info);
      });
  };
  handleDateApply = (event, picker) => {
    let startDate = picker.startDate.format("YYYY-MM-DD HH:mm");
    let endDate = picker.endDate.format("YYYY-MM-DD HH:mm");
    let Range = startDate + " / " + endDate;
    this.setState({
      selectedDateRange: Range,
    });
  };
  handelShowPictureModal = (item)=>{
    this.setState({
      pictureModal:item && !this.state.pictureModal,
      imageUrl:item
    })
   }

  render() {
    let template;
    const boolRfid = process.env.REACT_APP_RFID;
      template = (
        <div>
          <Row>
          {this.state.appLoadingState ? <LoadingMask /> : null}
          <Col md={3} lg={3}></Col>
          <Col md={9} lg={9}>
           
            <Link to="/students-reports/attendance-report/mark-attendance">
              <Button color="primary" className="pull-right  mb-3 button-full-width">
                <MdOutlinePostAdd size={20} /> &nbsp; Mark Attendance
              </Button>
            </Link>
          </Col>
        </Row>
          <AttendanceFilter
            onStudentNameChange={this.handelStudentNameChange}
            SelectedValues={this.state.selectedValues}
            onDDChange={this.handelDropDownChange}
            selectedAttendanceFilters={this.state.selectedAttendanceFilters}
            onFilterReset={this.handleAttendanceClear}
            onExportCsv={this.handleExportCsv}
            onApply={this.handleDateApply}
            selectedDateRange={this.state.selectedDateRange}
            onFilterFieldChange={this.handleFilterFieldChange}
            onFilterAttendance={this.handleApplyFilter}
          />
                  <ImageModel show={this.state.pictureModal} imageUrl={this.state.imageUrl} onModalClose={this.handelShowPictureModal}/>
          <Row>
            <Col className="pr-grid-col" xs={12} lg={12}>
              {this.state.attendanceList &&
              this.state.attendanceList.length > 0 ? (
                <Table
                  data={this.state.attendanceList}
                  isImageEnabled={true}
                  isImageCircle={true}
                  isImageSquare={[true, false]}
                  isCheckOption={false}
                  onShowPicture={this.handelShowPictureModal}
                  listItemToDisplay={[
                    { name: "Student", valueField: "name" },
                    { name: "Registration #", valueField: "reg_number" },
                    boolRfid && boolRfid === "true" ? { name: "RFID", valueField: "rfid" } : "",
                    { name: "class", valueField: "person_class" },
                    { name: "Section", valueField: "section" },
                    { name: "Attendance Status", valueField: "attendance_type" },
                    { name: "Date ", valueField: "attendance_date" },
                    { name: "IN-Time", valueField: "in_time" },
                    { name: "OUT-Time", valueField: "out_time" },
                  ]}
                  paginationData={this.state.paginationData}
                  buttonsToDisplay={[]}
                />
              ) : (
                <div className="text-center">
                  There is no data available to show
                </div>
              )}
            </Col>
          </Row>
        </div>
      );
    
    return (
      <div className="">{template} </div>);
  }
}

const actions = (dispatch) => ({
  notificationAction: (message, status) =>
    dispatch(notificationAction(message, status)),
  RecordPerPage: (recordperpage) =>
    dispatch({ type: ActionTypes.RECORDS_PER_PAGE, recordperpage }),
  PageNo: (pageno) => dispatch({ type: ActionTypes.PAGE_NO, pageno }),
});
const mapStateToProps = (state) => ({
  pageno: state.tab.pageno,
  recordperpage: state.tab.recordperpage,
  sortby: state.tab.sortBy,
  sortname: state.tab.sortName
});

export default connect(mapStateToProps, actions)(Attendance);
