import React, { Component } from "react";
import { Col, Row, Card, CardBody, CardTitle } from "reactstrap";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { IoMdCheckboxOutline } from "react-icons/io";
import { IoMdWarning } from "react-icons/io";
import { RiToggleLine } from "react-icons/ri";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { AiFillCloseCircle } from "react-icons/ai";
import ReactDatePicker from "react-datepicker";
import Widget from "../../components/Widget/Widget.js";
import ApexColumnBasic from "../../components/charts/ApexColumnBasic";
import ApexLineChart from "../../components/charts/ApexLineChart";
import { withRouter } from "react-router";

import dashboardpic from "../../assets/dashboard.png";
import "./cards/_card-stats.scss";
import "./cards/_cards.scss";
import "../../assets/sass/_common.scss";
import "./dashboard.scss";
import { getCounts, getMonthlyGraph, getWeeklyGraphs } from "./apiCalls.js";
import LoadingMaskDefault from "../../components/Loader/LoadingMaskDefault.js";

const isStudent = process.env.REACT_APP_IS_STUDENT;
const isStaff = process.env.REACT_APP_IS_STAFF;
export  class Dashboard extends Component {
  state = {
    selectedDate: new Date(),
    student_type:
      "Z0FBQUFBQmlNd2dXTVg4QklvWTVlOHBMdWE3QUc4Y1FfbnhxU2RVNGpVRDVabUNucVV3S0s4TTZJSi02RjhHRzVROWVoV052eUhBbm8xbU5KbmF4YUkwVXNxeGNIRmJwN0E9PQ==",
    staff_type:
      "Z0FBQUFBQmlqaVdIcXo5RldEZVhOekRDdllwdDRBNDFMYUVIdjJQMHFSR29CWHFtSjcxaVFCbjVDLUVSUlFCaUVFTDFneU80alZ2c0VqUm96REthZzBfaXBnQW1aa0dqLXc9PQ==",
    counts: [],
    monthAllDates: "",
    weeklyData: "",
    monthPresentSeries: [],
    appLoadingState: false,
    monthlyLoading: false,
    weeklyLoading: false,
  };
  componentDidMount() {
    this.getCount();
    let month = new Date().getMonth() + 1;
    const monthDates = this.getAllDaysInMonth(
      new Date().getFullYear(),
      new Date().getMonth()
    );

    this.getMonthlyData(new Date().getFullYear() + "-" + month);
    this.GetWeeklyData();
    this.setState({
      monthAllDates: monthDates,
    });
  }
  getAllDaysInMonth = (year, month) => {
    const date = new Date(year, month, 1);
    const dates = [];

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Ju",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentMonth = months[date.getMonth()];
    while (date.getMonth() === month) {
      dates.push(currentMonth + "-" + date.getDate());
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };
  handelDateChange = (e) => {
    const monthDates = this.getAllDaysInMonth(e.getFullYear(), e.getMonth());
    const month = e.getMonth() + 1;
    this.setState({
      selectedDate: e,
      monthAllDates: monthDates,
    });
    this.getMonthlyData(e.getFullYear() + "-" + month);
    // this.GetWeeklyData();
  };
  getMonthlyData = (payload) => {
    this.setState({
      monthlyLoading: true,
    });
    getMonthlyGraph(payload)
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            monthPresentSeries: response.data,
            monthlyLoading: false,
          });
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            monthlyLoading: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting staffs ", error));
  };
  GetWeeklyData = () => {
    this.setState({
      weeklyLoading: true,
    });
    getWeeklyGraphs()
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            weeklyData: response.data,
            weeklyLoading: false,
          });
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            weeklyLoading: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting staffs ", error));
  };
  getCount = () => {
    this.setState({
      appLoadingState: true,
    });

    let newArray = [];
    // Removing Logic for Student and Stadd Dashboard count///////

    // if (isStaff) {
    //   newArray.push(this.state.staff_type);
    // }
    // if (isStudent) {
    //   newArray.push(this.state.student_type);
    // }
    newArray.push(this.state.student_type);
    getCounts(newArray)
      .then((response) => {
        if (response.code == 200) {
          this.setState({
            counts: response.data,
            appLoadingState: false,
          });
        } else if (response.code != 200) {
          const info = {
            message: response.message,
            status: "error",
          };
          this.props.notificationAction(info);
          this.setState({
            appLoadingState: false,
          });
        }
      })
      .catch((error) => console.log("Erorr in getting staffs ", error));
  };

  render() {
    const {history} = this.props
    const {
      registered_employees,
      inactive,
      active1,
      ok_picture,
      pending,
      bad_picture,
      absent_today,
      present_today,
    } = this.state.counts;
    return (
      <>
       <meta http-equiv='cache-control' content='no-cache'/>
<meta http-equiv='expires' content='0'/>
<meta http-equiv='pragma' content='no-cache'/>
        <Row>
          {this.props.loader ? <LoadingMaskDefault /> : null}
          <Col xl="4" lg="4" md="6" sm="6">
            {!this.state.appLoadingState ? (
              <Card className="card-stats" onClick={()=> history.push({pathname :"/students/list-of-Students", state:{label: "All", value: -1}})}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="text-primary">
                          <BsFillPersonCheckFill size={70} />
                        </i>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Registered Students</p>
                        <CardTitle tag="p">
                          {registered_employees && registered_employees}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div className="minHeight card position-relative overflow-hidden">
                <div className="card-body">...</div>
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            )}
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            {!this.state.appLoadingState ? (
              <Card className="card-stats" onClick={()=> history.push({pathname :"/students/list-of-Students", state:{image_status:{label: "OK", value: "ok"}}})}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="text-success">
                          <IoMdCheckboxOutline size={70} />
                        </i>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Ok Profile Picture</p>
                        <CardTitle tag="p">
                          {ok_picture && ok_picture}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div className="minHeight card position-relative overflow-hidden">
                <div className="card-body">...</div>
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            )}
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            {!this.state.appLoadingState ? (
              <Card className="card-stats" onClick={()=> history.push({pathname :"/students/list-of-Students", state:{image_status:{label: "Error", value: "error"}}})}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="text-danger">
                          <IoMdWarning size={70} />
                        </i>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Bad Profile Picture</p>
                        <CardTitle tag="p">
                          {bad_picture && bad_picture}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div className="minHeight card position-relative overflow-hidden">
                <div className="card-body">...</div>
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            )}
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            {!this.state.appLoadingState ? (
              <Card className="card-stats" onClick={()=> history.push({pathname :"/students/list-of-Students", state:{ inactive:{label: "Inactive", value: "true"}}})}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="text-secondary">
                          <RiToggleLine size={70} />
                        </i>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Inactive Students</p>
                        <CardTitle tag="p">{inactive && inactive}</CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div className="minHeight card position-relative overflow-hidden">
                <div className="card-body">...</div>
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            )}
          </Col>

          <Col xl="4" lg="4" md="6" sm="6">
            {!this.state.appLoadingState ? (
              <Card className="card-stats" onClick={()=> history.push({pathname :"/students-reports/attendance-report", state:{ present:{label: "Present", value: "Z0FBQUFBQmltTFFLank0WmRRTUJEckZiQlhFN21adFZmS1FZZ1RXSkY2QXRYZkZKTWwzdndXOTlkNWprTGY1a0Q2SkRpYXgxS2g5dVF0d2lDWUFCZVNMUmNjcG01X2Q2bXc9PQ=="}}})}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="text-info">
                          <IoMdCheckmarkCircle size={70} />
                        </i>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Students Present Today</p>
                        <CardTitle tag="p">
                          {present_today && present_today}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div className="minHeight card position-relative overflow-hidden">
                <div className="card-body">...</div>
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            )}
          </Col>
          <Col xl="4" lg="4" md="6" sm="6">
            {!this.state.appLoadingState ? (
              <Card className="card-stats" onClick={()=> history.push({pathname :"/students-reports/attendance-report", state:{ present:{label: "Absent", value: "Z0FBQUFBQmltTFRmV1E5d1djTU9lM21LbkdQV0JJTlk1bVJqaGY2SXhkU1VPSkVxM1JXT3lIMGFxQ0s5cEZ0cy1yYjk5a05SbExIOTdXVTk0bkVZT2U5dE96enNwV2dyTnc9PQ=="}}})}>
                <CardBody>
                  <Row>
                    <Col md="4" xs="5">
                      <div className="icon-big text-center icon-warning">
                        <i className="text-danger">
                          <AiFillCloseCircle size={70} />
                        </i>
                      </div>
                    </Col>
                    <Col md="8" xs="7">
                      <div className="numbers">
                        <p className="card-category">Students Absent Today</p>
                        <CardTitle tag="p">
                          {absent_today && absent_today}
                        </CardTitle>
                        <p />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : (
              <div className="minHeight card position-relative overflow-hidden">
                <div className="card-body">...</div>
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                  <div className="spinner-border" role="status"></div>
                </div>
              </div>
            )}
          </Col>
          <Col xl="4" lg="4" md="6" sm="6"></Col>
        </Row>
        <Row>
          {" "}
          <Col className="pr-grid-col mt-3 mb-3" xs={12} lg={8}>
            <Row className="gutter ">
              <Col>
                {!this.state.monthlyLoading ? (
                  <Widget className="widget-p-md ">
                    <Row>
                      <Col sm={12} md={6} lg={6}>
                        <div className="headline-2">
                          Attendance History
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <div className="datepicker-position">
                          <ReactDatePicker
                            dateFormat="MMMM yyyy"
                            showMonthYearPicker
                            selected={this.state.selectedDate}
                            onChange={this.handelDateChange}
                            todayButton="Today"
                            maxDate={new Date()}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="graph">
                    <ApexColumnBasic
                      MonthDates={this.state.monthAllDates}
                      MonthPresentSeries={this.state.monthPresentSeries}
                    />
                    </div>
                  </Widget>
                ) : (
                  <Widget className="widget-p-md position-relative overflow-hidden">
                    <div className="monthly-card-loader card position-relative overflow-hidden">
                      <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                        <div className="spinner-border" role="status"></div>
                      </div>
                    </div>
                  </Widget>
                )}
              </Col>
            </Row>
          </Col>
          <Col className="pr-grid-col mt-3 mb-3" xs={12} lg={4}>
            <Row className="gutter">
              <Col>
                {!this.state.weeklyLoading ? (
                  <Widget className="widget-p-md ">
                    <div className="headline-2 mb-3">Weekly Attendance</div>
                    <div className="graph">
                    <ApexLineChart WeeklyData={this.state.weeklyData} />
                    </div>
                  </Widget>
                ) : (
                  <Widget className="widget-p-md position-relative overflow-hidden">
                    <div className="graph card position-relative overflow-hidden">
                      <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                        <div className="spinner-border" role="status"></div>
                      </div>
                    </div>
                  </Widget>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
       
      </>
    );
  }
}
export default withRouter(Dashboard)